import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Font,
} from "../../commons/Theme";

export const StyledImpactCall = styled.div`
  .mobileOnly {
    display: inline-block;
    width: 100%;

    @media (${BP.tablet}) {
      display: none;
    }
  }

  .desktopOnly {
    display: none;

    @media (${BP.tablet}) {
      display: inline-block;
      width: 100%;
    }
  }
`;
export const StyledForm = styled.form`
  display: flex;
  align-items: stretch;
  width: 96%;
  margin-left:2%;
  @media (${BP.tablet}) {
    justify-content: center;
    background-color: transparent;
    width: 50%;
    margin-left:25%;
  }
`;

export const StyledInput = styled.input`
  appearance: none;
  width: 80%;
  font-size: ${Rem(14)};
  color: ${Colors.white};
  background-color: ${Colors.black};
  border: 1px solid ${Colors.grayLight};
  border-radius: 0;
  margin-right:${Rem(8)};
  padding: ${Rem(6)} ${Rem(10)};
  font-family: ${Font.sans};
  border-radius: ${Rem(30)};
`;

export const StyledSubmit = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: ${Rem(48)};
  border-style:none;
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  text-decoration: none;
  cursor: pointer;
  font-size: ${Rem(18)};
  border-radius: ${Rem(30)};
  padding: 0 ${Rem(28)};
  @media (${BP.tablet}) {
    font-size: ${Rem(24)};
    height: ${Rem(58)};
  }
`;
export const StyledButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: ${Rem(38)};
  color: ${Colors.white};
  background-color: ${Colors.yellow};
  text-decoration: none;
  cursor: pointer;
  font-size: ${Rem(18)};
  border-radius: ${Rem(40)};
  box-shadow: 0 ${Rem(4)} ${Rem(4)} 0 rgba(0, 0, 0, 0.25);
  padding: 0 ${Rem(28)};
  margin: 0 auto ${Rem(8)};

  @media (${BP.tablet}) {
    font-size: ${Rem(24)};
    height: ${Rem(58)};
  }

  ${(props) =>
    props.big
    && css`
      height: ${Rem(45)};
    `}

  ${(props) =>
    props.isGrey
    && css`
      font-size: ${Rem(14)};
      height: ${Rem(50)};
      color: ${Colors.black};
      background-color: #c4c4c4;
      box-shadow: none;
      margin-bottom: 0;

      > img {
        margin-right: ${Rem(6)};
      }
    `}
`;

export const StyledHeader = styled.header`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${Colors.white};
  padding: ${Rem(26)} ${Rem(28)};
  z-index: 50;

  @media (${BP.tablet}) {
    justify-content: center;
    background-color: transparent;
  }

  @media (${BP.ipad}) {
    padding: ${Rem(52)} ${Rem(28)};
  }
`;

export const StyledHeaderLogo = styled.a`
  @media (${BP.tablet}) {
    position: absolute;
    top: 50%;
    left: ${Rem(24)};
    transform: translateY(-50%);
  }
  @media (${BP.ipad}) {
    left: ${Rem(48)};
  }
  img {
    width: ${Rem(68)};

    @media (${BP.tablet}) {
      width: ${Rem(78)};
    }
  }
`;

export const StyledHeaderNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledHeaderNavItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Colors.black};
  text-decoration: none;
  font-size: 0;
  margin-left: ${Rem(24)};
  cursor: pointer;

  @media (${BP.tablet}) {
    font-size: ${Rem(18)};
    margin-left: ${Rem(32)};
    background-color: ${Colors.white};
    border-radius: ${Rem(20)};
    height: ${Rem(30)};
    padding: 0 ${Rem(8)};
    
    img {
      max-height: 70%;
      margin-right: ${Rem(4)};
    }
  }

  ${(props) =>
    props.first
    && css`
      img {
        position: relative;
        top: ${Rem(0)};
      }
    `}
  
  ${(props) =>
    props.second
    && css`
      img {
        position: relative;
        top: ${Rem(-4)};
        @media (${BP.tablet}) {
          top: ${Rem(-2)};
        }
      }
    `}

  ${(props) =>
    props.thirth
    && css`
      img {
        position: relative;
        top: ${Rem(6)};
        @media (${BP.tablet}) {
          top: ${Rem(2)};
        }
      }
    `}

  ${(props) =>
    props.fourth
    && css`
      img {
        position: relative;
        top: ${Rem(4)};
        @media (${BP.tablet}) {
          top: ${Rem(2)};
        }
      }
    `}
`;

export const StyledHero = styled.div`
  text-align: center;
  padding: ${Rem(120)} 0 0;
  
  @media (${BP.tablet}) {
    text-align: left;
    padding-top: ${Rem(100)};
    margin-bottom: ${Rem(200)};
  }

  @media (${BP.desktop}) {
    padding-top: ${Rem(120)};
    margin-bottom: ${Rem(320)};
  }

  h1 {
    position: relative;
    font-size: ${Rem(38)};
    font-weight: bold;
    text-shadow: 0 ${Rem(4)} ${Rem(4)} rgba(0, 0, 0, 0.25);
    text-align: center;
    padding: 0 ${Rem(24)};
    z-index: 2;

    @media (${BP.tablet}) {
      text-align: left;
      font-size: ${Rem(64)};
      line-height: ${Rem(32)};
    }

    @media (${BP.ipad}) {
      padding: 0 ${Rem(48)};
    }

    @media (${BP.desktop}) {
      margin-bottom: ${Rem(40)};
    }

    > span {
      font-size: ${Rem(24)};
      text-shadow: none;

      @media (${BP.tablet}) {
        display: inline-block;
        padding-top: ${Rem(24)};
        font-size: ${Rem(36)};
      }

      &:first-child {
        font-weight: normal;
      }

      > span {
        position: relative;
        display: inline-block;
        font-size: ${Rem(30)};
        font-weight: bold;
        color: ${Colors.yellow};

        @media (${BP.tablet}) {
          font-size: ${Rem(36)};
          margin-bottom: ${Rem(22)};
        }

        &:after {
          display: block;
          content: '';
          position: absolute;
          width: 100%;
          height: ${Rem(2)};
          bottom: ${Rem(-8)};
          left: 0;
          background-color: ${Colors.black};

          @media (${BP.tablet}) {
            bottom: ${Rem(-16)};
          }
        }
      }
    }

    > small {
      display: inline-block;
      font-size: ${Rem(6)};
      color: #6b676e;
      text-shadow: none;
      line-height: ${Rem(8)};
      max-width: ${Rem(220)};
      margin: 0 auto ${Rem(14)};

      @media (${BP.tablet}) {
        max-width: ${Rem(400)};
        font-size: ${Rem(12)};
        line-height: ${Rem(16)};
      }
    }
  }

  ${StyledButton} {
    position: relative;
    z-index: 2;

    @media (${BP.tablet}) {
      margin-left: ${Rem(24)};
    }

    @media (${BP.ipad}) {
      margin-left: ${Rem(48)};
    }
  }

  > img {
    position: relative;
    margin-top: ${Rem(-28)};
    margin-bottom: ${Rem(-28)};
    z-index: 1;

    @media (${BP.tablet}) {
      min-width: ${Rem(960)};
      position: absolute;
      top: ${Rem(-40)};
      left: 0;
      width: 100%;
      z-index: 1;
    }
  }
`;

export const StyledPromotore = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media (${BP.tablet}) {
    flex-direction: row-reverse;
    margin: 0 auto ${Rem(-180)};
  }

  @media (${BP.large}) {
    margin-bottom: ${Rem(-120)};
  }
`;

export const StyledPromotoreContent = styled.div`
  text-align: center;
  padding: 0 ${Rem(24)} ${Rem(60)};

  @media (${BP.tablet}) {
    text-align: left;
    max-width: ${Rem(730)};
  }

  h2 {
    font-size: ${Rem(36)};
    font-weight: bold;
    color: ${Colors.yellow};
    margin-bottom: ${Rem(30)};

    @media (${BP.tablet}) {
      font-size: ${Rem(64)};
    }
  }

  p {
    font-size: ${Rem(11)};
    line-height: ${Rem(25)};
    font-weight: normal;
    margin-bottom: ${Rem(28)};

    @media (${BP.tablet}) {
      font-size: ${Rem(18)};
      line-height: ${Rem(35)};
    }
  }
`;

export const StyledPromotoreImage = styled.img`
  width: ${Rem(269)};

  @media (${BP.ipad}) {
    width: ${Rem(404)};
  }
`;

export const StyledCallFor = styled.div`
  text-align: center;
  background-color: #f5f5f5;
  margin-top: ${Rem(-24)};

  @media (${BP.tablet}) {
    position: relative;
    margin-top: ${Rem(-80)};
    margin-bottom: ${Rem(-80)};
    z-index: 2;
  }

  > h2 {
    font-size: ${Rem(36)};
    line-height: ${Rem(55)};
    font-weight: bold;
    padding: 0 ${Rem(24)};

    @media (${BP.tablet}) {
      font-size: ${Rem(64)};
      line-height: ${Rem(70)};
      padding-bottom: ${Rem(48)};
    }
  }

  > p {
    font-size: ${Rem(11)};
    line-height: ${Rem(25)};
    font-weight: normal;
    color: #585661;
    padding: 0 ${Rem(24)};

    @media (${BP.tablet}) {
      font-size: ${Rem(18)};
      line-height: ${Rem(35)};
      padding-bottom: ${Rem(48)};
      max-width: ${Rem(984)};
      margin: 0 auto;
    }
  }

  > strong {
    display: inline-block;
    font-size: ${Rem(13)};
    line-height: ${Rem(25)};
    padding: 0 ${Rem(24)};

    @media (${BP.tablet}) {
      font-size: ${Rem(24)};
      line-height: ${Rem(35)};
      max-width: ${Rem(984)};
      margin: 0 auto;
    }
  }

  > img {
    width: 100%;
  }
`;

export const StyledCallForItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${BP.tablet}) {
    flex-direction: row;
    align-items: flex-start;
  }

  @media (${BP.ipad}) {
    padding: 0 ${Rem(16)};
  }
`;

export const StyledCallForItem = styled.div`
  h3 {
    font-size: ${Rem(18)};
    line-height: ${Rem(35)};
    font-weight: bold;
    color: ${Colors.yellow};
    padding: 0 ${Rem(32)} ${Rem(40)};

    @media (${BP.tablet}) {
      font-size: ${Rem(24)};
      line-height: ${Rem(35)};
    }
  }

  p {
    font-size: ${Rem(11)};
    line-height: ${Rem(25)};
    font-weight: normal;
    padding: 0 ${Rem(32)};

    @media (${BP.tablet}) {
      font-size: ${Rem(18)};
      line-height: ${Rem(35)};
    }
  }

  &:first-child {
    @media (${BP.tablet}) {
      text-align: left;
    }
  }

  &:last-child {
    @media (${BP.tablet}) {
      text-align: right;
    }
  }
`;

export const StyledPrices = styled.div`
  text-align: center;
  padding: 0 ${Rem(24)};
  margin-top: ${Rem(-18)};

  @media (${BP.tablet}) {
    margin-top: ${Rem(-80)};
  }

  > h2 {
    font-size: ${Rem(36)};
    font-weight: bold;
    color: ${Colors.yellow};
    padding-bottom: ${Rem(32)};

    @media (${BP.tablet}) {
      font-size: ${Rem(72)};
    }
  }

  > p {
    font-size: ${Rem(11)};
    line-height: ${Rem(25)};
    font-weight: normal;

    @media (${BP.tablet}) {
      font-size: ${Rem(18)};
      line-height: ${Rem(35)};
      max-width: ${Rem(835)};
      margin: 0 auto ${Rem(32)};
    }
  }
`;

export const StyledPricesItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Rem(22)};

  @media (${BP.tablet}) {
    flex-direction: row;
    align-items: stretch;
  }

  @media (${BP.ipad}) {
    padding-bottom: ${Rem(40)};
    margin-bottom: ${Rem(30)};
  }

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 100%;
    max-width: ${Rem(1090)};
    height: 1px;
    background-color: ${Colors.yellow};
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
`;

export const StyledPricesItemsGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (${BP.tablet}) {
    flex-direction: row-reverse;
  }

  ${() => css`
    ${StyledPricesItem}:last-child {
      @media (${BP.tablet}) {
        position: relative;
        top: ${Rem(-10)};
      }
    }
  `}

  ${() => css`
    ${StyledPricesItem}:first-child {
      @media (${BP.ipad}) {
        position: relative;
        top: ${Rem(11)};
      }

      @media (min-width: 1200px) {
        top: ${Rem(12)};
        margin: 0 ${Rem(48)} ${Rem(16)};
      }
    }
  `}
`;

export const StyledPricesItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${Rem(16)};

  h3 {
    font-size: ${Rem(24)};
    color: ${Colors.yellow};
    font-weight: bold;
    padding-bottom: ${Rem(24)};

    @media (${BP.ipad}) {
      font-size: ${Rem(36)};
    }
  }

  strong {
    font-size: ${Rem(24)};
    font-weight: bold;
    padding-bottom: ${Rem(8)};

    @media (${BP.ipad}) {
      font-size: ${Rem(36)};
    }
  }

  p {
    font-size: ${Rem(12)};
    font-weight: normal;
    line-height: ${Rem(25)};

    @media (${BP.ipad}) {
      font-size: ${Rem(18)};
      line-height: ${Rem(35)};
    }
  }

  ${(props) =>
    props.first
    && css`
      img {
        width: ${Rem(255)};
      }
    `}

  ${(props) =>
    props.second
    && css`
      img {
        width: ${Rem(226)};
      }
    `}
  
  ${(props) =>
    props.thirth
    && css`
      img {
        width: ${Rem(210)};
      }
    `}
`;

export const StyledPricesCert = styled.div`
  h2 {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${Colors.yellow};
    font-size: ${Rem(18)};
    font-weight: bold;
    margin-bottom: ${Rem(18)};

    @media (${BP.tablet}) {
      flex-direction: row;
      font-size: ${Rem(24)};
    }

    img {
      margin-bottom: ${Rem(12)};

      @media (${BP.tablet}) {
        margin-right: ${Rem(12)};
        margin-bottom: 0;
      }
    }
  }

  p {
    font-size: ${Rem(11)};
    line-height: ${Rem(25)};
    font-weight: normal;
    margin-bottom: ${Rem(24)};

    @media (${BP.tablet}) {
      font-size: ${Rem(18)};
      line-height: ${Rem(35)};
      max-width: ${Rem(754)};
      margin: 0 auto ${Rem(24)};
    }
  }
`;

export const StyledRules = styled.div`
  text-align: center;
  background-color: #f5f5f5;
  overflow: hidden;
  margin-top: ${Rem(-12)};

  @media (${BP.tablet}) {
    position: relative;
    margin-top: ${Rem(-100)};
    text-align: left;

    > * {
      position: relative;
      z-index: 2;
    }
  }

  h2 {
    font-size: ${Rem(36)};
    line-height: ${Rem(35)};
    font-weight: bold;
    padding: 0 ${Rem(24)} ${Rem(26)};

    @media (${BP.tablet}) {
      font-size: ${Rem(64)};
      padding-bottom: ${Rem(40)};
    }

    @media (${BP.ipad}) {
      padding-left: ${Rem(48)};
      padding-right: ${Rem(48)};
    }
  }

  p {
    font-size: ${Rem(18)};
    line-height: ${Rem(25)};
    font-weight: normal;
    padding: 0 ${Rem(24)} ${Rem(44)};
    color: #585661;

    @media (${BP.tablet}) {
      font-size: ${Rem(24)};
      line-height: ${Rem(35)};
    }

    @media (${BP.ipad}) {
      padding-left: ${Rem(48)};
      padding-right: ${Rem(48)};
    }
  }

  ${StyledButton} {
    @media (${BP.tablet}) {
      margin-left: ${Rem(24)};
      margin-bottom: ${Rem(40)};
    }

    @media (${BP.ipad}) {
      margin-left: ${Rem(48)};
    }
  }

  > img {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
`;

export const StyledRulesTimer = styled.div`
  margin-bottom: ${Rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (${BP.tablet}) {
    display: inline-flex;
    margin-left: ${Rem(24)};
  }

  @media (${BP.ipad}) {
    margin-left: ${Rem(48)};
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: ${Rem(12)};
    margin: 0 ${Rem(4)};

    @media (${BP.tablet}) {
      font-size: ${Rem(20)};
      margin: 0 ${Rem(18)} 0 0;
    }

    > span {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: ${Rem(60)};
      height: ${Rem(55)};
      border-radius: ${Rem(18)};
      font-size: ${Rem(24)};
      color: ${Colors.black};
      background-color: ${Colors.yellow};
      margin-bottom: ${Rem(6)};

      @media (${BP.tablet}) {
        width: ${Rem(116)};
        height: ${Rem(106)};
        font-size: ${Rem(48)};
      }
    }
  }
`;

export const StyledRulesProposal = styled.div`
  width: ${Rem(302)};
  margin: ${Rem(72)} auto ${Rem(114)};
  padding: ${Rem(28)} ${Rem(12)} ${Rem(24)};
  border-radius: ${Rem(40)};
  background-color: ${Colors.white};
  font-size: ${Rem(14)};
  font-weight: normal;
  text-align: center;

  @media (${BP.tablet}) {
    width: ${Rem(500)};
    font-size: ${Rem(24)};
    margin: ${Rem(60)} 0 ${Rem(144)} ${Rem(24)};
    padding: ${Rem(36)} ${Rem(12)} ${Rem(36)};
  }

  @media (${BP.ipad}) {
    padding: ${Rem(48)} ${Rem(12)} ${Rem(38)};
    margin-bottom: ${Rem(224)};
    margin-left: ${Rem(48)};
  }

  @media (min-width: 1200px) {
    margin-top: ${Rem(100)};
    margin-bottom: ${Rem(264)};
  }

  @media (${BP.desktop}) {
    margin-top: ${Rem(160)};
    margin-bottom: ${Rem(284)};
  }

  @media (${BP.large}) {
    margin-top: ${Rem(200)};
    margin-bottom: ${Rem(340)};
  }

  ${StyledButton} {
    height: ${Rem(36)};
    font-size: ${Rem(14)};
    box-shadow: none;
    margin-top: ${Rem(16)};
    margin-bottom: 0;
    margin-left: auto;

    @media (${BP.tablet}) {
      height: ${Rem(60)};
      font-size: ${Rem(24)};
    }
    @media (${BP.ipad}) {
      margin-top: ${Rem(42)};
    }
  }
`;

export const StyledPartners = styled.div`
  text-align: center;
  margin-bottom: ${Rem(0)};
  margin-top: ${Rem(60)};
  @media (${BP.ipad}) {
    margin-bottom: ${Rem(50)};
  }
  @media (min-width: 1200px) {
    position: relative;
    margin-top: ${Rem(60)};
    z-index: 2;
  }

  h2 {
    font-size: ${Rem(36)};
    font-weight: bold;
    margin-bottom: ${Rem(4)};

    @media (${BP.tablet}) {
      font-size: ${Rem(48)};
    }
  }
  > div {
    position: relative;
  }
`;

export const StyledPartnersCarousel = styled.div`
  position: relative;
  min-height: ${Rem(150)};
  
  @media (${BP.tablet}) {
    max-width: calc(100% - ${Rem(200)});
  }

  @media (${BP.ipad}) {
    max-width: calc(100% - ${Rem(240)});
  }

  @media (${BP.desktop}) {
    max-width: calc(100% - ${Rem(360)});
  }

  .swiper-wrapper {
    align-items: stretch;
  }
`;

export const StyledPartnersCarouselItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${Rem(100)};

  @media (${BP.tablet}) {
    width: auto;
    margin: 0 ${Rem(48)};
  }

  @media (${BP.ipad}) {
    margin: 0 auto;
  }

  > img {
    max-width: ${Rem(220)};
  }
`;

export const StyledPartnersCarouselControl = styled.div`
  position: absolute;
  width: ${Rem(46)};
  top: 50%;
  left: ${Rem(24)};
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;

  @media (${BP.tablet}) {
    width: ${Rem(72)};
  }

  @media (${BP.ipad}) {
    left: ${Rem(48)};
  }

  img {
    width: 100%;
  }

  ${() => css`
    ${StyledPartnersCarouselControl} + & {
      left: auto;
      right: ${Rem(24)};

      @media (${BP.ipad}) {
        right: ${Rem(48)};
      }
    }
  `}
`;

export const StyledFooter = styled.footer`
  text-align: center;
  background-color: #f5f5f5;
  padding: ${Rem(24)} ${Rem(24)} ${Rem(48)};

  @media (${BP.tablet}) {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  @media (${BP.ipad}) {
    padding: ${Rem(40)} ${Rem(48)} ${Rem(60)};
  }

  h2 {
    font-size: ${Rem(30)};
    font-weight: bold;
    color: ${Colors.yellow};
    margin-bottom: ${Rem(26)};

    @media (${BP.tablet}) {
      font-size: ${Rem(36)};
      margin-bottom: ${Rem(16)};
    }
  }

  p {
    font-size: ${Rem(14)};
    line-height: ${Rem(30)};

    @media (${BP.tablet}) {
      white-space: nowrap;
      font-size: ${Rem(18)};
    }

    > span {
      display: inline-block;
      padding-top: ${Rem(28)};

      > img {
        margin-right: ${Rem(8)};
        vertical-align: sub;
      }
    }
  }

  > div {
    &:first-child {
      margin-bottom: ${Rem(40)};

      @media (${BP.tablet}) {
        text-align: right;
      }
    }

    &:last-child {
      @media (${BP.tablet}) {
        text-align: left;
        padding-top: ${Rem(28)};
      }

      div:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: ${Rem(60)};

        @media (${BP.tablet}) {
          justify-content: flex-start;
          margin-bottom: ${Rem(30)};
        }

        img {
          width: ${Rem(76)};
        }

        div {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          text-align: left;
          padding-left: ${Rem(8)};
          margin-left: ${Rem(8)};
          font-size: ${Rem(18)};
          line-height: ${Rem(20)};
          font-weight: bold;

          > span {
            display: inline-block;
            font-size: ${Rem(20)};
            font-weight: bold;
            color: ${Colors.yellow};
            padding-top: ${Rem(8)};
          }

          &:before {
            position: absolute;
            display: block;
            content: '';
            width: 1px;
            height: 100%;
            top: 0;
            left: 0;
            background-color: ${Colors.black};
          }
        }
      }

      div:first-child + div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: ${Rem(18)};
        line-height: ${Rem(30)};
        font-weight: bold;
        margin-bottom: ${Rem(75)};

        @media (${BP.tablet}) {
          align-items: flex-start;
          margin-bottom: 0;
          max-width: ${Rem(620)};
        }

        > span {
          display: inline-block;
          padding-top: ${Rem(40)};
          font-weight: 300;

          @media (${BP.tablet}) {
            padding-top: ${Rem(30)};
          }
        }
      }

      nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @media (${BP.tablet}) {
          position: absolute;
          width: auto;
          right: ${Rem(24)};
          bottom: ${Rem(60)};
        }

        > a {
          display: inline-block;
          text-decoration: none;
          margin: 0 auto;
          color: ${Colors.black};
          font-size: ${Rem(11)};
          @media (${BP.tablet}) {
            font-size: ${Rem(12)};
          }
        }

        > span {
          display: inline-block;
          width: 1px;
          height: ${Rem(16)};
          background-color: ${Colors.black};

          @media (${BP.tablet}) {
            height: ${Rem(26)};
            margin: 0 ${Rem(8)};
          }
        }
      }
    }
  }
`;
