const useScrollController = () => {
  const scrollToCssSelector = (cssSelector, topMargin = 0) => {
    if (cssSelector && document.querySelector(cssSelector)) {
      var rect = document.querySelector(cssSelector).getBoundingClientRect();
      var offsetTop = window.pageYOffset + rect.top - topMargin;

      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    } 
  };

  const scrollToTop = (topMargin = 0) => {
    window.scrollTo({
      top: topMargin,
      behavior: "smooth",
    });
  };

  return {
    scrollToCssSelector,
    scrollToTop,
  };
};

export default useScrollController;
